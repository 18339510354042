body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "PT Serif", serif;
}

#footer_site {
  background: #f4ede5;
  padding: 2.5rem 0;
  color: #49595e;
  font-size: .9em;
}

#logo.webheader, #logo a.webheader, #logo a.webheader:hover {
  font-weight: bold;
  text-transform: uppercase;
  color: #0c234b;
  font-size: 1.8em;
  text-decoration: none;
  display: flex;
  line-height: .9em;
  text-align: left;
}
.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}

.Home .lander {
  padding: 80px 0;
  text-align: center;
}

.Home .lander h1 {
  font-weight: 500;
}

.Home .tables h1 {
  font-weight: 500;
}

span.text-size-h6 {
  font-weight: 400;
}

.Home .lander p {
  color: #999;
}

.expand-btn {
  padding-top: 0;
}

a.pos-info-link:hover, a.pos-info-link:focus {
  color: white;
}

a {
  word-wrap: break-word;
}
.youtubeContainer {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    margin-bottom: 10px;
}

.youtubeContainer iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.NotFound {
  padding-top: 100px;
  text-align: center;
}

